import React from 'react';
import './CloseButton.css';

class CloseButton extends React.Component{

    render(){
        return(
            <button className={`CloseButton ${this.props.type} ${this.props.transitionState}`} onClick={this.props.onClick}>
                <span className={"CloseButtonIcon " + this.props.type}>
                    <span/>
                    <span/>
                </span>
            </button>
        );
    }
}

export default CloseButton;