import React from 'react';
import "./imprint-box.css";
import CloseButton from '../CloseButton/CloseButton.js';
import Card from '../Card/Card.js';

class ImprintBox extends React.Component {

    constructor(props) {
        super(props);
        this.scrollContainerRef = React.createRef();
    }

    onClose(){
        this.props.onClose();
        this.scrollContainerRef.current.scrollTop = 0;
    }

    render(){
        const transitionState = "entered";
        return(
            <div className={`imprint-box__background${this.props.isActive ? "" : " hidden"}`}>
                <div ref={this.scrollContainerRef} className={`imprint-box${this.props.isActive ? "" : " hidden"}`}>
                    <Card type="imprint" onMount={this.props.onMount} defaultSize={false} transitionState={transitionState}>
                        <div className={`imprint__pop-up ${transitionState}`}>
                            <CloseButton type="imprint" transitionState={this.props.isActive ? "entered" : "exited"} onClick={() => this.onClose()}/>
                            <div className={`imprint__column-left ${transitionState}`}>
                                <div className="imprint__header">
                                    <img src={this.props.image} alt="imprint-card-icon"></img>
                                    <div className='imprint__header--title'>{this.props.title}</div>
                                </div>
                            </div>
                            <div className={`imprint__column-right ${transitionState}`}>
                                <div className={`imprint__text ${this.props.isBigText ? "big" : "small"} ${transitionState}`}>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.text}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default ImprintBox;