import React from 'react';
import './click-gallery.css';

class ClickGallery extends React.Component {

    static SCROLL_TIMEOUT = 200;

    constructor(props) {
        super(props);
        this.state = { selectedItem: 0 };
        this.galleryRef = React.createRef();
    }

    previousItem(){
        var focusedItem = this.state.selectedItem;
        this.selectItem(focusedItem - 1);
    }

    nextItem(){
        var focusedItem = this.state.selectedItem;
        this.selectItem(focusedItem + 1);
    }

    selectItem(focusedItem) {
        var gallery = this.galleryRef.current;
        const numItems = gallery.children.length;

        if(numItems === 0) return;
        if(focusedItem < 0) focusedItem = numItems-1;
        if(focusedItem >= numItems) focusedItem = 0;

        this.setState({ selectedItem: focusedItem })
        for (var i = 0; i < gallery.children.length; i++) {
            var isVisible = i === focusedItem;
            gallery.children[i].className = `click-gallery__image${isVisible ? "" : " hidden"}`;
        }
    }

    render() {
        var numImages = this.props.src == null ? 0 : this.props.src.length;

        if (numImages === 0) return <div className='click-gallery' />;

        var bulletPoints = [];
        for (let i = 0; i < numImages; i++) {
            bulletPoints.push(this.buildBulletPoint(i === this.state.selectedItem, i));
        }

        var images = [];
        for (let i = 0; i < numImages; i++) {
            var isVisible = i === this.state.selectedItem;
            const leftArea = numImages === 1 ? "" : <span className="left" onClick={() => this.previousItem()}/>;
            const rightArea = numImages === 1 ? "" : <span className="right" onClick={() => this.nextItem()}/>;
            
            images.push(
                <div key={i} className={`click-gallery__image${isVisible ? "" : " hidden"}`}>
                    <img src={this.props.src[i]} alt={`text-bild-${i}`}/>
                    {leftArea}
                    {rightArea}
                </div>
            );
        }

        var btnPrev = numImages === 1 ? "" : <div className={`click-gallery__ui__button ${this.props.className} left`} onClick={() => this.previousItem()}>
                <svg width="10px" viewBox="0 0 5 8">
                    <path id="galleryArrow" d="M0.94,7.489l3.649,-3.649l-3.649,-3.649l-0.509,0.509l3.14,3.14c-0,-0 -3.14,3.14 -3.14,3.14l0.509,0.509Z"/>
                </svg>
            </div>;
        var btnNext = numImages === 1 ? "" : <div className={`click-gallery__ui__button ${this.props.className} right`} onClick={() => this.nextItem()}>
                <svg width="10px" viewBox="0 0 5 8">
                    <use href="#galleryArrow"/>
                </svg>
            </div>;


        return (
            <div className='click-gallery' >
                <div className='click-gallery__ui'>
                    {btnPrev}
                    <div ref={this.galleryRef} className='click-gallery__images'>
                        {images}
                    </div>
                    {btnNext}
                </div>
                <div className={`click-gallery__bulletpoints ${this.props.className}`}>
                    {bulletPoints}
                </div>
                
            </div>
        );
    }

    buildBulletPoint(isSelected, index) {
        if (isSelected) {
            return (<span key={index} className='selected' onClick={() => this.onBulletPointClicked(index)} />);
        }
        else {
            return (<span key={index} onClick={() => this.onBulletPointClicked(index)} />);
        }
    }

    onBulletPointClicked(bulletPointIndex) {
        console.log("bullet point " + bulletPointIndex + " clicked");
        this.selectItem(bulletPointIndex);
    }
}

export default ClickGallery;