import './Header.css';

function Header(props) {
    return (
      <header className="Header">
        <img src={props.logo} className="header-logo" alt="logo" />
      </header>
    );
  }

  export default Header;