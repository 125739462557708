import React from 'react';
import CardOpenBtn from '../../CardOpenBtn/CardOpenBtn.js';
import CloseButton from '../../CloseButton/CloseButton.js';
import Card from './../Card.js';
import './calendar-card.css';
import '../card.css';
import ClickGallery from '../../ClickGallery/ClickGallery.js';

const months_de = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

class CalendarCard extends React.Component
{
    getMonthName(month) {
        if(isNaN(month)) return month;

        if(month < 0) month = 0;
        else if(month > 11) month = 11;

        return months_de[month];
    }

    onCardDidMount(cardRef) {
        this.cardRef = cardRef;
        if(this.props.focusAfterLoading) {
            setTimeout(() => {
                this.onButtonClicked();
            }, 200);
        }
    }

    onButtonClicked() {
        this.props.onClick(this.cardRef, "calendar", this.props);
    }

    render() {
        const card = this.props.focused === true ? this.renderOpenCard() : this.renderClosedCard();
        return (
            card
        );
    }

    renderClosedCard() {
        const isPopUp = false;
        const dateInfos = this.renderDate(isPopUp);
        return(
            <Card 
                type="calendar" 
                opacity={this.props.opacity}
                onMount={this.onCardDidMount.bind(this)}
                >
                <div className="bevelBox"/>
                {dateInfos}             
                <CardOpenBtn 
                    type="calendar"
                    onClick={this.onButtonClicked.bind(this)}
                    card={this}>
                        weiter
                </CardOpenBtn>
            </Card>
        );
    }

    renderOpenCard() {
        const isPopUp = true;
        const dateInfos = this.renderDate(isPopUp, this.props.transitionState);
        const gallery = this.props.gallery == null || this.props.gallery.length === 0 ? "" : 
            <div className={`card__pop-up__gallery ${this.props.transitionState}`}>
                <ClickGallery className="calendar" src={this.props.gallery}/>
            </div>;

        return (
            <Card type="calendar" onMount={this.props.onMount} defaultSize={false} transitionState={this.props.transitionState}>
                <div className='bevelBox'/>
                <div className={`card__pop-up ${this.props.transitionState}`}>
                    <CloseButton type="calendar" transitionState={this.props.transitionState} onClick={this.props.onClosing}/>
                    <div className={`card__pop-up__column-left ${this.props.transitionState}`}>
                        {dateInfos}
                        {gallery}
                    </div>
                    <div className={`card__pop-up__column-right ${this.props.transitionState}`}>
                        <div className={`card__pop-up__text calendar ${this.props.transitionState}`}>
                            <div className='card__pop-up__title calendar'>{this.props.title}</div>     
                            <div dangerouslySetInnerHTML={{ __html: this.props.text}}>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    renderDate(isPopUp, state) {
        var day = (typeof this.props.day === "number") && this.props.day < 10 ? `0${this.props.day}` : '' + this.props.day;

        var time = this.props.to != null  && this.props.to !== '' ? `${this.props.from}–${this.props.to}` : `${this.props.from}`;

        return(
            <div className={`calendar__date ${isPopUp ? "pop-up" : "idle"}${state == null ? "" : " " + state}`}>
                <div className='calendar__date--day'>{day}</div>
                <div className='calendar__date--wrapper'>
                    <div className="calendar__date--month">{this.getMonthName(this.props.month)} {this.props.year}</div>
                    <div className="calendar__date--time">{time}</div>
                </div>
                <div className={`calendar__date--title${state == null ? "" : " " + state}`}>{this.props.title}</div>                                                
            </div>
        );
    }
}

export default CalendarCard;