import CardSet from '../CardSet/CardSet.js';
import CalendarCard from '../Card/Calendar/CalendarCard.js';
import StoryCard from '../Card/Story/StoryCard.js';
import PopUpContainer from '../PopUpContainer/PopUpContainer';
import Footer from '../Footer/Footer.js';
import '../PopUpContainer/pop-up-container.css';
import './AppContent.css';
import React from 'react';
import MemoryCard from '../Card/Memory/MemoryCard.js';
import ImprintBox from '../ImprintBox/ImprintBox.js';
import Sponsors from '../Sponsors/Sponsors.js';
import FilterButton from '../FilterButton/FilterButton';
import FilterMenu from '../FilterMenu/FilterMenu.js';

class AppContent extends React.Component {

    TRANSITION_DURATION = 800;

    constructor(props) {
        super(props);
        this.state = { 
            popUpActive: false, 
            imprintActive: false, 
            privacyActive: false,
            calendarActive: true,
            storyActive: true,
            memoryActive: true,
            filterMenuActive : false
        };
    }

    onPopUpCreated(callbackOnCardClicked) {
        this.callbackOnCardClicked = callbackOnCardClicked;
    }

    onCardClicked(card, cardType, cardProps) {
        if (this.callbackOnCardClicked === undefined || this.callbackOnCardClicked === null) {
            console.warn("[AppContent] this.callbackOnCardClicked is undefined");
            return;
        }

        if (card !== undefined) {
            card.current.style.opacity = 0;
            this.setState({ popUpActive: true, currentCardRef: card });
            this.props.onCardStateChanged(true);
        }

        this.callbackOnCardClicked(card, cardType, cardProps);
    }

    onCardClosed() {
        setTimeout(() => {
            var card = this.state.currentCardRef;
            card.current.style.opacity = 1;
            this.setState({ currentCardRef: card });
        }, this.TRANSITION_DURATION);
        this.setState({ popUpActive: false });
        this.props.onCardStateChanged(false);
    }

    render() {
        var urlParams = new URLSearchParams(window.location.search);
        var tagToFocus;
        if(urlParams.has("goto")) {
            tagToFocus = urlParams.get("goto");
        }

        const cards = [];
        const numCalendarCards = this.props.data.calendarCards.edges.length;
        const numAboutUsCards = this.props.data.aboutUsCards.edges.length;
        const numMemoryCards = this.props.data.memoryCards.edges.length;

        let sortedCards = [];
        this.props.data.calendarCards.edges.forEach(val => sortedCards.push(Object.assign({}, val)));
        sortedCards.sort(function(a, b){
            let dateA = new Date(a.node.calendarCardData.date);
            let dateB = new Date(b.node.calendarCardData.date);
            return dateA.getTime() < dateB.getTime() ? -1 : 1;
        });

        var ci = 0;
        var ai = 0;
        var mi = 0;
        while (
            (this.state.calendarActive && ci < numCalendarCards) || 
            (this.state.storyActive && ai < numAboutUsCards) || 
            (this.state.memoryActive && mi < numMemoryCards)) {
            if (this.state.calendarActive && ci < numCalendarCards) {
                let cardData = sortedCards[ci].node;
                let focus = tagToFocus !== undefined && cardData.calendarCardData.tag === tagToFocus;
                let card = this.createCalendarCard(cardData, `${cardData.id}_${ci}`, focus);
                cards.push(card);
                ci++;
            }
            if (this.state.storyActive && ai < numAboutUsCards) {
                let cardData = this.props.data.aboutUsCards.edges[ai].node;
                let focus = tagToFocus !== undefined && cardData.aboutUsCardData.tag === tagToFocus;
                let card = this.createAboutUsCard(cardData, `${cardData.id}_${ai}`, focus);
                cards.push(card);
                ai++;
            }
            if (this.state.memoryActive && mi < numMemoryCards) {
                let cardData = this.props.data.memoryCards.edges[mi].node;
                let focus = tagToFocus !== undefined && cardData.memoryCardData.tag === tagToFocus;
                let card = this.createMemoryCard(cardData, `${cardData.id}_${mi}`, focus);
                cards.push(card);
                mi++;
            }
        }

        let imprintData = this.props.data.imprintCards.edges[0].node;
        const imprint = <ImprintBox
            isActive={this.state.imprintActive}
            title={imprintData.title}
            text={imprintData.content}
            isBigText={true}
            image={imprintData.imprintData.icon.sourceUrl}
            onClose={() => this.onImprintClosing()}
        />;

        let privacyData = this.props.data.privacyPolicyCards.edges[0].node;
        const privacy = <ImprintBox
            isActive={this.state.privacyActive}
            title={privacyData.title}
            text={privacyData.content}
            image={privacyData.privacyPolicyData.icon != null ? privacyData.privacyPolicyData.icon.sourceUrl : ""}
            onClose={() => this.onPrivacyClosing()}
        />;

        let supporterLogos = [];
        const logos = this.props.data.supporterLogos.edges;
        for(var i=0; logos != null && i < logos.length; i++){
            var logoData = logos[i].node.supporterData;
            if(logoData == null || logoData.logo == null) continue;
            supporterLogos.push(logoData.logo.sourceUrl);
        }

        const filterMenu = this.state.filterMenuActive ? <FilterMenu
            calendarActive={this.state.calendarActive}
            storyActive={this.state.storyActive}
            memoryActive={this.state.memoryActive}
            onClick={(filterId) => this.onFilterChanged(filterId)}
        /> : "";

        return (
            <div className="AppContent">
                <FilterButton onClick={() => this.onFilterButtonClicked()} isSelected={this.state.filterMenuActive}/>
                {filterMenu}
                <CardSet>
                    {cards}
                </CardSet>
                <Sponsors logos={supporterLogos}/>               
                <Footer onImprintClicked={() => this.onImprintClicked()} onPrivacyClicked={() => this.onPrivacyClicked()} />
                {imprint}
                {privacy}
                <PopUpContainer
                    onRefCreated={this.onPopUpCreated.bind(this)}
                    isActive={this.state.popUpActive}
                    onClosing={this.onCardClosed.bind(this)}
                    transitionDuration={this.TRANSITION_DURATION}
                />
            </div>
        );
    }

    onFilterButtonClicked(){
        this.setState({filterMenuActive: !this.state.filterMenuActive})
    }

    onFilterChanged(filterId){
        switch(filterId){
            case "calendar":
                this.setState({calendarActive: !this.state.calendarActive});
                break;
            case "story":
                this.setState({storyActive: !this.state.storyActive});
                break;
            case "memory":
                this.setState({memoryActive: !this.state.memoryActive});
                break;
            default:
                return;
        }
    }

    onImprintClicked() {
        this.setState({ imprintActive: true });
    }

    onImprintClosing() {
        this.setState({ imprintActive: false });
    }

    onPrivacyClicked() {
        this.setState({ privacyActive: true });
    }

    onPrivacyClosing() {
        this.setState({ privacyActive: false });
    }

    createCalendarCard(cardData, id, focus) {
        var date = new Date(cardData.calendarCardData.date);
        var year = date.getFullYear().toString();
        var galleryImages = this.getGalleryData(cardData.calendarCardData);

        return <CalendarCard key={id}
            day={date.getDate()}
            month={date.getMonth()}
            year={year}
            from={cardData.calendarCardData.startTime}
            to={cardData.calendarCardData.endTime}
            title={cardData.title}
            text={cardData.content}
            gallery={galleryImages}
            onClick={this.onCardClicked.bind(this)}
            focusAfterLoading={focus}
        />;
    }

    getGalleryData(cardData) {
        var galleryUrls = [];
        this.readImageUrlToArray(cardData.galleryImage1, galleryUrls);
        this.readImageUrlToArray(cardData.galleryImage2, galleryUrls);
        this.readImageUrlToArray(cardData.galleryImage3, galleryUrls);
        this.readImageUrlToArray(cardData.galleryImage4, galleryUrls);
        this.readImageUrlToArray(cardData.galleryImage5, galleryUrls);
        return galleryUrls;
    }

    readImageUrlToArray(src, array) {
        var imgUrl = this.getImageUrl(src);
        if (imgUrl != null) array.push(imgUrl);
    }

    getImageUrl(galleryImage) {
        if (galleryImage == null || galleryImage.sourceUrl == null) return null;
        return galleryImage.sourceUrl;
    }

    createAboutUsCard(cardData, id, focus) {
        var graphic = cardData.aboutUsCardData.smileyGraphic != null ? cardData.aboutUsCardData.smileyGraphic.sourceUrl : "";
        var subtitle = cardData.aboutUsCardData.subtitle != null ? cardData.aboutUsCardData.subtitle : "";
        var galleryImages = this.getGalleryData(cardData.aboutUsCardData);

        return <StoryCard key={id}
            title={cardData.title}
            subtitle={subtitle}
            image={graphic}
            text={cardData.content}
            gallery={galleryImages}
            onClick={this.onCardClicked.bind(this)}
            focusAfterLoading={focus}
        />
    }

    createMemoryCard(cardData, id, focus) {
        var cardImage = cardData.memoryCardData.cardImage != null ? cardData.memoryCardData.cardImage.sourceUrl : "";
        var galleryImages = this.getGalleryData(cardData.memoryCardData);

        return <MemoryCard
            key={id}
            title={cardData.title}
            subtitle={cardData.memoryCardData.subtitle}
            image={cardImage}
            text={cardData.content}
            gallery={galleryImages}
            onClick={this.onCardClicked.bind(this)}
            focusAfterLoading={focus}
        />
    }
}

export default AppContent;
