import "./scroll-top-button.css"

function ScrollTopButton(props){
    if(!props.isVisible) return "";

    return(
        <div className={`scroll-top-button`} onClick={props.onClick}>
            <svg width="40px" height="40px" viewBox="0 0 10 10">
                <polyline className="scroll-top-button--arrow" points="2,6 5,3 8,6"/>
            </svg>
        </div>
    );
}

export default ScrollTopButton;