import React from 'react';
import "./social-media.css";

function SocialMedia(props){
    const basePath = "icons/social-media";
    return(
        <div className='social-media'>
            <a href="https://www.facebook.com/lokallabor" target="_blank" rel="noreferrer">
                <img src={`./${basePath}/fb.svg`} alt="facebook logo"/>
            </a> 
            <a href="https://www.instagram.com/lokallabor_berlin/" target="_blank" rel="noreferrer">
                <img src={`./${basePath}/instagram.svg`} alt="instagram logo"/>
            </a>
            <a href="https://t.me/lokallabordudenschaenke" target="_blank" rel="noreferrer">
                <img src={`./${basePath}//telegram.svg`} alt="telegram logo"/>
            </a>
            <a href="https://newsletter.lokallabor.de" target="_blank" rel="noreferrer">
                <img src={`./icons/newsletter.svg`} alt="newsletter icon"/>
            </a>
        </div>
    );
}

export default SocialMedia;