import React from 'react';
import './card.css';


class Card extends React.Component{

    constructor(props){
        super(props);

        this.elementRef = React.createRef();

        this.setElementRef = element => {
            this.elementRef = element;
          };
    }

    componentDidMount()
    {
        if(this.props.onMount != null) this.props.onMount(this.elementRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot){
    }

    render(){

        var sizePropertiesClass = this.props.defaultSize === false ? "fullScreen" : "defaultSize";
        if(this.props.transitionState != null)
        {
            sizePropertiesClass += " " + this.props.transitionState;
        }

        const classNames = `Card ${sizePropertiesClass} ${this.props.type}`;

        return (
            <div ref={this.elementRef} style={{opacity: this.props.opacity === undefined ? 1 : this.props.opacity}} className={classNames} >
                {this.props.children}
            </div>
        );
    }
}

export default Card;