import React from 'react';
import CardOpenBtn from '../../CardOpenBtn/CardOpenBtn.js';
import CloseButton from '../../CloseButton/CloseButton.js';
import Card from '../Card.js';
import './story-card.css';
import ClickGallery from '../../ClickGallery/ClickGallery.js';

class StoryCard extends React.Component
{
    onCardDidMount(cardRef)
    {
        this.cardRef = cardRef;
        if(this.props.focusAfterLoading) {
            setTimeout(() => {
                this.onButtonClicked();
             }, 200);
        }
    }

    onButtonClicked()
    {
        this.props.onClick(this.cardRef, "info", this.props);
    }

    render()
    {
        const card = this.props.focused === true ? this.renderOpenCard() : this.renderClosedCard();
        return (
            card
        );
    }

    renderClosedCard()
    {
        const header = this.renderHeader();
        return(
            <Card 
                type="story" 
                opacity={this.props.opacity}
                onMount={this.onCardDidMount.bind(this)}
                >
                {header}                
                <CardOpenBtn 
                    type="story"
                    onClick={this.onButtonClicked.bind(this)}
                    card={this}>
                        weiter
                </CardOpenBtn>
            </Card>
        );
    }

    renderOpenCard()
    {
        const header = this.renderHeader();
        const gallery = this.props.gallery != null && this.props.gallery.length > 0 ? <div className={`card__pop-up__gallery ${this.props.transitionState}`}>
            <ClickGallery className="story" src={this.props.gallery}/>
        </div> : "";

        return (
            <Card 
                type="story" 
                onMount={this.props.onMount} 
                defaultSize={false} 
                transitionState={this.props.transitionState}
                >
                <div className={`card__pop-up ${this.props.transitionState}`}>
                    <CloseButton type="story" transitionState={this.props.transitionState} onClick={this.props.onClosing}/>
                    <div className={`card__pop-up__column-left ${this.props.transitionState}`}>
                        {header}                        
                        {gallery}
                    </div>
                    <div className={`card__pop-up__column-right ${this.props.transitionState}`}>
                        <div className={`card__pop-up__text story ${this.props.transitionState}`}>
                            <div className='card__pop-up__subtitle story'>{this.props.subtitle}</div>     
                            <div dangerouslySetInnerHTML={{ __html: this.props.text}}>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    renderHeader()
    {
        return (
            <div className="story__header">
                <img src={this.props.image} alt="story-card-icon"></img>
                <div className={`story__header--title`}>{this.props.title}</div>
            </div>
        );
    }
}

export default StoryCard;