import React from 'react';
import CardOpenBtn from '../../CardOpenBtn/CardOpenBtn.js';
import CloseButton from '../../CloseButton/CloseButton.js';
import Card from '../Card.js';
import './memory-card.css';
import ClickGallery from '../../ClickGallery/ClickGallery.js';

class MemoryCard extends React.Component
{
    static CARD_TYPE = "memory";

    onCardDidMount(cardRef)
    {
        this.cardRef = cardRef;
        if(this.props.focusAfterLoading){
            setTimeout(() => {
                this.onButtonClicked();
            }, 200);
        }
    }

    onButtonClicked()
    {
        this.props.onClick(this.cardRef, MemoryCard.CARD_TYPE, this.props);
    }

    render()
    {
        const card = this.props.focused === true ? this.renderOpenCard() : this.renderClosedCard();
        return (
            card
        );
    }

    renderClosedCard()
    {
        const header = this.renderHeader();
        return(
            <Card 
                type={MemoryCard.CARD_TYPE} 
                opacity={this.props.opacity}
                onMount={this.onCardDidMount.bind(this)}
                >
                <div className="bevelBox"/>
                {header}                
                <CardOpenBtn 
                    type={MemoryCard.CARD_TYPE}
                    onClick={this.onButtonClicked.bind(this)}
                    card={this}>
                        Rückblick
                </CardOpenBtn>
            </Card>
        );
    }

    renderOpenCard()
    {
        const header = this.renderHeader();
        return (
            <Card 
                type={MemoryCard.CARD_TYPE} 
                onMount={this.props.onMount} 
                defaultSize={false} 
                transitionState={this.props.transitionState}
                >
                <div className="bevelBox"/>
                <div className={`card__pop-up ${this.props.transitionState}`}>
                    <CloseButton type={MemoryCard.CARD_TYPE} transitionState={this.props.transitionState} onClick={this.props.onClosing}/>
                    <div className={`card__pop-up__column-left ${this.props.transitionState}`}>
                        {header}                                            
                        <div className={`card__pop-up__gallery memory ${this.props.transitionState}`}>
                            <ClickGallery className="memory" src={this.props.gallery}/>
                        </div>
                    </div>
                    <div className={`card__pop-up__column-right ${this.props.transitionState}`}>
                        <div className={`card__pop-up__text memory ${this.props.transitionState}`}>
                            <div dangerouslySetInnerHTML={{ __html: this.props.text}}>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    renderHeader()
    {
        var transitionState = this.props.transitionState !== undefined ? " " + this.props.transitionState : "";

        return (
            <div className={`memory__header${transitionState}`}>
                <img className={`memory__header--image${transitionState}`} src={this.props.image} alt="memory-card-icon" style={{objectFit: 'cover'}}></img>
                <div className="memory__header--title">{this.props.title}</div>
                <div className="memory__header--subtitle">{this.props.subtitle}</div>
            </div>
        );
    }
}

export default MemoryCard;