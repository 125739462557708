import logo from './Header/lokallabor_logo.svg';
import './App.css';
import Header from './Header/Header.js';
import React from 'react';
import AppContentQuery from './AppContentQuery/AppContentQuery';
import { gql } from '@apollo/client';
import ScrollTopButton from './ScrollTopButton/ScrollTopButton';

const GET_CARDS = gql`
{
  calendarCards {
    edges {
      node {
        id,
        content,
        title,
        calendarCardData{
          tag,
          date,
          startTime,
        	endTime,
          galleryImage1 {
            sourceUrl
          }
          galleryImage2 {
            sourceUrl
          }
          galleryImage3 {
            sourceUrl
          }
          galleryImage4 {
            sourceUrl
          }
          galleryImage5 {
            sourceUrl
          }
        }
      }
    }
  },  
  aboutUsCards {
    edges {
      node {
        id,
        title,
        content,
        aboutUsCardData {
          tag,
          subtitle,
          smileyGraphic {
            caption,
            mediaDetails{
              height,
              width
            },
            sourceUrl
          },
          galleryImage1 {
            sourceUrl
          },
          galleryImage2 {
            sourceUrl
          },
          galleryImage3 {
            sourceUrl
          },
          galleryImage4 {
            sourceUrl
          },
          galleryImage5 {
            sourceUrl
          }
        }
      }
    }
  },
  memoryCards {
    edges {
      node {
        id,
        content,
        title,
        memoryCardData{
          tag,
          subtitle,
          cardImage{
            sourceUrl
          },
          galleryImage1{
            sourceUrl
          },
          galleryImage2{
            sourceUrl
          },
          galleryImage3{
            sourceUrl
          },
          galleryImage4{
            sourceUrl
          },
          galleryImage5{
            sourceUrl
          }
        }
      }
    }
  },
  imprintCards {
    edges {
      node {
        id,
        title,
        content,
        imprintData {
          icon {
            sourceUrl
          }
        }
      }
    }
  },
  privacyPolicyCards {
    edges {
      node {
        id,
        title,
        content,
        privacyPolicyData {
          icon {
            sourceUrl
          }
        }
      }
    }
  },
  supporterLogos {
    edges {
      node {
        id,
        supporterData {
          logo{
            sourceUrl
          }
        }
      }
    }
	}
}
`;

class App extends React.Component {

  static SCROLL_TRESHOLD = 200;

  constructor(props){
    super(props);
    this.state = {disableScroll: false, belowScrollThreshold: true};
    this.scrollContainer = React.createRef();
  }

  onCardOpened(){
    this.setState({disableScroll: true});
  }

  onCardClosed(){
    this.setState({disableScroll: false});
  }

  onCardStateChanged(cardIsOpen){
    if(cardIsOpen) this.onCardOpened();
    else this.onCardClosed();
  }

  onScroll(){
    const scrollTop = this.scrollContainer.current.scrollTop;
  
    if(scrollTop < App.SCROLL_TRESHOLD) {
      if(this.state.belowScrollThreshold === false) this.setState({belowScrollThreshold: true});
      return;
    }

    if(this.state.belowScrollThreshold === false) return;
    
    this.setState({belowScrollThreshold: false});
  }

  scrollToTop(){
    this.scrollContainer.current.scrollTo({top: 0, behavior: 'smooth'});
  }

  render() {
    return (
      <div ref={this.scrollContainer} className={`App${this.state.disableScroll ? " disableScroll" : ""}`} onScroll={() => this.onScroll()}>
        <Header logo={logo} />
        <AppContentQuery query={GET_CARDS} onCardStateChanged={(state) => this.onCardStateChanged(state)}/>
        <ScrollTopButton isVisible={!this.state.belowScrollThreshold} onClick={() => this.scrollToTop()}/>
      </div>
    );
  }
}

export default App;
