import React from 'react';
import SocialMedia from '../SocialMedia/SocialMedia';
import "./footer.css";

function Footer(props){
    return (
        <div className='footer'>
            <div className='footer__imprint' onClick={() => props.onImprintClicked()}> 
                Impressum
            </div>
            <div className='footer__privacy' onClick={() => props.onPrivacyClicked()}> 
                Datenschutz
            </div>
            <SocialMedia/>
        </div>
    );
}

export default Footer;