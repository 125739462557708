import React from 'react';
import CalendarCard from '../Card/Calendar/CalendarCard.js';
import StoryCard from '../Card/Story/StoryCard.js';
import MemoryCard from '../Card/Memory/MemoryCard.js';
import {Transition} from "react-transition-group";

class PopUpContainer extends React.Component{

    constructor(props){
        super(props);
        
        this.state = {spawnStyle: undefined, transitionStyles: undefined, isVisible: false};
        this.elementRef = React.createRef();
    }

    componentDidMount(){
        this.props.onRefCreated(this.spawnCard.bind(this));
    }

    componentDidUpdate(){
        this.props.onRefCreated(this.spawnCard.bind(this));
    }

    spawnCard(cardRef, cardType, cardProps){ 
        const refRect = cardRef.current.getBoundingClientRect();

        const margin = 20;
        const duration = this.props.transitionDuration;

        const spawnStyle = {
            position: 'absolute',
            boxSizing: 'border-box',
            transition: `left ${duration}ms ease-in-out, 
                top ${duration}ms ease-in-out,
                width ${duration}ms ease-in-out,
                height ${duration}ms ease-in-out,
                minHeight ${duration}ms ease-in-out,
                padding ${duration}ms ease-in-out,
                opacity ${duration}ms`,
            opacity: 1,
            padding: 0,
            top: refRect.top,
            left: refRect.left,
            width: refRect.width,
            height: refRect.height,
        };

        const transitionStyles = {
            entering: {
                top: refRect.top,
                left: refRect.left,
                with: refRect.width,
                height: refRect.height,
                opacity: 0,
                padding: 0,
            },
            entered: {
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                padding: margin,
                opacity: 1,
                pointerEvents: 'all',
                overflow: 'scroll'
            },
            exiting: {
                top: refRect.top,
                left: refRect.left,
                with: refRect.width,
                height: refRect.height,
                padding: 0,
                opacity: 1,
            },
            exited: {
                padding: 0,
                opacity: 0,
            }
          };
        
        const updatedState = {
            spawnStyle: spawnStyle, 
            transitionStyles: transitionStyles, 
            isVisible: true, 
            cardType: cardType,
            cardProps: cardProps
        };

        this.setState(updatedState);
    }

    onCardSpawned(cardRef)
    {
    }

    onExited()
    {
        this.setState({isVisible: false});
    }

    render(){        
        let result;
        let card;

        if(this.state.isVisible)
        {
            const cardProps = this.state.cardProps;
            switch(this.state.cardType)
            {
                case "info":
                    card = (transitionState) => <StoryCard
                        title={cardProps.title}
                        subtitle={cardProps.subtitle}
                        image={cardProps.image}
                        text={cardProps.text}
                        gallery={cardProps.gallery}
                        onMount={this.onCardSpawned.bind(this)} 
                        onClosing={this.props.onClosing}
                        transitionState={transitionState}
                        focused={true}/>
                    ;
                    break;
                case "memory":
                    card = (transitionState) => <MemoryCard
                        title={cardProps.title}
                        subtitle={cardProps.subtitle}
                        image={cardProps.image}
                        text={cardProps.text}
                        gallery={cardProps.gallery}
                        onMount={this.onCardSpawned.bind(this)} 
                        onClosing={this.props.onClosing}
                        transitionState={transitionState}
                        focused={true}/>
                    ;
                    break;
                case "calendar":                    
                    card = (transitionState) => <CalendarCard
                        day={cardProps.day}
                        month={cardProps.month}
                        year={cardProps.year}
                        from={cardProps.from}
                        to={cardProps.to}
                        title={cardProps.title}
                        text={cardProps.text}
                        gallery={cardProps.gallery}
                        onMount={this.onCardSpawned.bind(this)} 
                        onClosing={this.props.onClosing}
                        transitionState={transitionState}
                        focused={true}/>
                    ;
                    break;
                default:
                    card = "";
            }

            result = 
                <Transition
                    nodeRef={this.elementRef}
                    in={this.props.isActive} 
                    timeout={{
                        appear: 0,
                        enter: 0,
                        exit: this.props.transitionDuration,
                    }}
                    appear={true}
                    onExited={this.onExited.bind(this)}
                    >
                    {(state) => (
                        <div className={`pop-up-container${this.props.isActive ? " active" : ""}`}>
                            <div className={`pop-up-container--background ${state}`}></div>
                            <div
                                ref={this.elementRef}
                                style={{
                                    ...this.state.spawnStyle,
                                    ...this.state.transitionStyles[state]
                                }}>
                                {card(state)}
                            </div>
                        </div>
                    )}
                </Transition>;
        }
        else
        {
            result = "";
        }

        return(result);
    }
}

export default PopUpContainer;