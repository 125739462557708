import React from 'react';
import "./sponsors.css";

function Sponsors(props){
    if(props.logos == null) return "";

    var logos = [];
    for(var i=0; i < props.logos.length; i++){
        logos.push(<img key={i} src={props.logos[i]} alt='sponsor-logo'/>);
    }

    return(
        <div className='sponsors'>
            <div className='sponsors__caption'>
                Das Projekt Lokallabor wird gefördert durch:
            </div>
            <div className='sponsors__logos'>
                {logos}
            </div>
        </div>
    );
}

export default Sponsors;