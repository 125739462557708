import './filter-menu.css';

function FilterOption(props){

    const closeIcon = props.isSelected ? <span className={"filter-option__close-icon"}>
        <span/>
        <span/>
    </span> : ""

    return(
        <div className={`filter-option ${props.isSelected ? "selected" : ""}`} onClick={() => props.onClick(props.id)}>
            {props.title}
            {closeIcon}
        </div>
    );
}

export default FilterOption;