import "./filter-menu.css"
import FilterOption from "./FilterOption";

function FilterMenu(props){
    return(
        <div className="filter-menu">
            <FilterOption id="calendar" title="Veranstaltungen" isSelected={props.calendarActive} onClick={props.onClick}/>
            <FilterOption id="story" title="Vereinsinfos" isSelected={props.storyActive} onClick={props.onClick}/>
            <FilterOption id="memory" title="Eventarchiv" isSelected={props.memoryActive} onClick={props.onClick}/>
        </div>
    );
}

export default FilterMenu;