import React from 'react';
import "./filter-button.css";

function FilterButton(props){

    const arrow =  <polyline className="filter-button--arrow" points="2,6 5,3 8,6"/>
    const filterIcon = <polyline className="filter-button--arrow" points="5,7.5 5,5.5 3,3.25 7,3.25 5.1,5.375"/>; //<path className="filter-button--filter-icon" d="M4.8,7.2L4.8,5.28L2.88,3.12L6.72,3.12L4.897,5.171" />;

    return(
        <div className={`filter-button${props.isSelected ? " selected" : ""}`} onClick={props.onClick}>
            <svg width="40px" height="40px" viewBox="0 0 10 10">
                {props.isSelected ? arrow : filterIcon}
            </svg>
        </div>
    );
}

export default FilterButton;