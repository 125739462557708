import React from 'react';
import './CardOpenBtn.css';

class CardOpenBtn extends React.Component{

    render(){
        return(
            <button className={'cardOpenBtn ' + this.props.type} onClick={this.props.onClick}>{this.props.children}</button>
        );
    }
}

export default CardOpenBtn;