import './CardSet.css';


function CardSet(props){
    return (
        <div className="CardSet">
          {props.children}
        </div>
      );
}

export default CardSet;