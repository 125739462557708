import { useQuery } from '@apollo/client';
import AppContent from '../AppContent/AppContent';
import './app-content-query.css';

function AppContentQuery(props){
    const { loading, error, data } = useQuery(props.query);
      
    if (loading) return <img className="loading-symbol" src="../../lade-face.svg" alt='loading-symbol'/>;
    if (error) return <p>Error : {error.message}</p>;
      
    return (
        <AppContent data={data} onCardStateChanged={props.onCardStateChanged}/>
    );
}

export default AppContentQuery;